// Detect page language
/**
 *
 * @param fallback
 * @returns {string}
 */
export default function getPageLanguage(fallback = "de") {
  let language = fallback;
  if (window.location.href.includes("/de/")) {
    language = "de";
  } else if (window.location.href.includes("/en/")) {
    language = "en";
  } else if (window.location.href.includes("/ru/")) {
    language = "ru";
  }
  return language;
}
